<template>
	<div>
		<a-modal v-model:visible="visible" width="800px" @cancel="close">
			<template #title>
				<modalHeader title="盘点任务下达"></modalHeader>
			</template>
			<template #footer>
				<a-button @click="close">取消</a-button>
				<a-button @click="onSubmit" type="primary">下达任务</a-button>
			</template>
			<a-form ref="formRef" :model="formModel" :label-col="{ span: 4 }">
				<a-form-item label="盘点任务名称" name="name" :rules="[{ required: true, message: '必填项不允许为空' }]">
					<a-input v-model:value="formModel.name" disabled></a-input>
				</a-form-item>
				<!-- v-model:value="tempDate" -->
				<a-form-item label="任务提交时间" name="expectationTime" :rules="[{ required: true, message: '必填项不允许为空' }]">
					<a-date-picker  v-model:value="formModel.expectationTime" :disabled-date="disabledDateFun" showTime></a-date-picker>
				</a-form-item>
				
				<!-- <a-form-item label="希望提交时间">
					<a-range-picker></a-range-picker>
				</a-form-item> -->
				
				<!-- <a-form-item label="预警时间">
					【希望提交时间】前
					<a-input-number></a-input-number> 
					天
					<a-range-picker></a-range-picker>
				</a-form-item> -->
				
				<a-form-item label="任务接收人" name="departmentIds" :rules="[{ required: true, message: '必填项不允许为空' }]">
					<a-checkbox-group v-model:value="formModel.departmentIds">
						<a-checkbox  v-for="(item, index) in recipientData" :key="index" :value="item.id">{{item.nickname}}</a-checkbox>
						<!-- <a-checkbox >李四</a-checkbox> -->
					</a-checkbox-group>
				</a-form-item>
				
				<a-form-item label="通知文件">
					<filesUpload v-model:value="formModel.fileList"></filesUpload>
				</a-form-item>
			</a-form>
		</a-modal>
	</div>
</template>

<script>
	import moment from 'moment';
	import { assignUnitTask, leaderListByUnitId } from '@/service/modules/inventory.js';
	import modalHeader from '@/components/modalHeader/index.vue';
	import filesUpload from '@/components/upload/filesUpload.vue';
	export default {
		components: { modalHeader, filesUpload },
		data(){
			return {
				visible: false,
				formModel: {
					fileList: [],
				},
				recipientData: {},
				fileList: [],
				tempDate: 0
			}
		},
		created() {
		},
		methods: {
			async getData(id) {
				try {
					let ret = await leaderListByUnitId({
						unitTaskId: id
					});
					if (ret.code === 200) {
						this.recipientData = ret.data;
						this.formModel.departmentIds = this.recipientData.map(item => {
							return item.id
						})
					}
				} catch(e) {

				}
			},
			onSubmit() {
				this.$refs.formRef.validate().then(async () => {
					let postData = this.$deepClone(this.formModel)
					// console.log("1111",postData)
					let tempDepId = postData.departmentIds
					postData.departmentIds = tempDepId.join(',');
					postData.materialUrl = this.formModel.fileList.toLocaleString()
					let temp = this.transDateTime(postData.expectationTime)
					let tempTime = new Date(temp)
					postData.expectationTime = tempTime.valueOf()
					postData.expectationTime = this.moment(postData.expectationTime).unix();
					postData = JSON.parse(JSON.stringify(postData));
					// this.loading = true;
					try {
						let ret = await assignUnitTask(postData);
						// this.loading = false;
						if (ret.code === 200) {
							this.$message.success("发布成功");
							this.$emit('onReset');
							this.close();
						}
					} catch(e) {
						// this.loading = false;
					}
				})
			},
			open(e) {
				this.formModel = {
					name: e.name,
					fileList: [e.informUrl],
					expirationTime: e.expirationTime,
					// informUrl: e.informUrl,
					unitTaskId: e.unitTaskId
				}
				if(e.informUrl == "") this.formModel.fileList =  []
				if(e.expectationTime == 0) {
					this.formModel.expectationTime = this.moment.unix(e.expirationTime)
				} else {
					this.formModel.expectationTime = this.moment.unix(e.expectationTime)
				}
				this.getData(e.unitTaskId)
				this.visible = true;
			},
			close() {
				this.$refs.formRef.clearValidate();
				this.formModel={
					fileList: []
				}
				this.visible = false;
			},
			disabledDateFun(current) {
				// return current && (current > moment().endOf('day') || current < moment().subtract(2,'months'));
				// return current && current < this.formModel.expectationTime
			}
		},
	}
</script>

<style>
</style>